<template>
  <!--  评价人审核 -->
  <div class="jxAppraiserReview">
    <van-nav-bar
      :title="$t('jxAppraiserReview.revTxt1')"
      left-text
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="overflow_div">
      <van-pull-refresh
        v-model="isLoadingAwait"
        :success-text="$t('module.refreshSuccessfully')"
        @refresh="onRefreshAwait"
      >
        <van-list
          v-model="loadingAwait"
          ref="appraisersList"
          :finished="finishedAwait"
          :finished-text="$t('module.noMore')"
          @load="onLoadAwait"
        >
          <van-collapse
            class="program_collapse"
            v-model="programName"
            accordion
          >
            <van-collapse-item
              class="program_collapse_item"
              v-for="(el, id) in appraisersList"
              :key="id"
              :title="el.khplanname"
              :name="id + 1"
            >
              <van-collapse v-model="activeNames" class="children_collapse">
                <van-collapse-item
                  v-for="(item, index) in el.children"
                  :key="index"
                  :title="item.levelName"
                  :name="index + 1"
                  :value="item.pjper"
                >
                  <van-cell
                    class="ass_cell"
                    center
                    v-for="(it, idx) in item.levelArray.filter(
                      le => le.empcode != null
                    )"
                    :key="idx"
                    :title="it.empname"
                    :value="it.deptname"
                    :label="it.empcode"
                  >
                    <template #icon>
                      <img :src="it.picurl" alt />
                    </template>
                  </van-cell>
                </van-collapse-item>
              </van-collapse>
              <div class="test_button">
                <!-- <span class="disagree"
                      disabled
                      @click="disagreeEntry(el.autoid)">
                </span>
                <span @click="agreeEntry(el.autoid)">同意</span> -->

                <div class="wai_div">
                  <van-button
                    :disabled="el.isagreekpjr"
                    type="warning"
                    @click="disagreeEntry(el.autoid)"
                    >{{ $t('jxAppraiserReview.revTxt2') }}</van-button
                  >
                </div>
                <div class="wai_div">
                  <van-button
                    :disabled="el.isagreekpjr"
                    type="info"
                    @click="agreeEntry(el.autoid)"
                    >{{ $t('jxAppraiserReview.revTxt3') }}</van-button
                  >
                </div>
              </div>
            </van-collapse-item>
          </van-collapse>
        </van-list>
      </van-pull-refresh>
    </div>
    <!-- <div class="entry_button">
      <span class="disagree"
            @click="disagreeEntry">不同意</span>
      <span @click="agreeEntry">同意</span>
    </div>-->
    <van-dialog
      v-model="showDisagree"
      :title="$t('jxAppraiserReview.revTxt4')"
      show-cancel-button
      confirmButtonColor="#2B8DF0"
      @confirm="confirm"
      @close="closeDia"
    >
      <van-field
        v-model="disagreeReason"
        name="disagreeReason"
        rows="8"
        class="disagreeReason"
        autosize
        type="textarea"
        :placeholder="$t('jxAppraiserReview.revTxt5')"
        show-word-limit
      />
    </van-dialog>
  </div>
</template>

<script>
import { Toast, Dialog } from 'vant'
import {
  getUser360PjrList,
  agreeUser360Pjr,
  notAgreeUser360Pjr
} from '@/api/wxjx.js'
import { checkPic } from '@/utils'
export default {
  data() {
    const userInfo = localStorage.userInfo
      ? JSON.parse(localStorage.userInfo)
      : {}
    return {
      userInfo,
      programName: 1,
      activeNames: [1, 2, 3],
      isLoadingAwait: false,
      loadingAwait: false,
      finishedAwait: false,
      appraisersList: [],
      showDisagree: false,
      disagreeReason: '', // 不同意原因
      programAutoid: 0 // 方案autoid
    }
  },
  mounted() {
    this.$refs.appraisersList.check()
  },
  methods: {
    // 回退
    onClickLeft() {
      this.$router.push('/home')
    },
    onLoadAwait() {
      // 异步更新数据
      // 加载状态开始
      if (this.isLoadingAwait) {
        this.appraisersList = []
        this.isLoadingAwait = false
      }
      // this.loadingAwait = true;
      getUser360PjrList({ empid: this.userInfo.empid }).then(res => {
        let arr = []
        let khplanid = 0
        res.data.forEach((e, index) => {
          let children = []
          let levelArray1 = []
          let levelArray2 = []
          let levelArray3 = []
          if (e.khplanid != khplanid) {
            for (let i = 1; i <= 20; i++) {
              if (e['sjempid' + i])
                levelArray1.push({
                  empid: e['sjempid' + i],
                  empname: e['sjempname' + i],
                  deptname: e['sjdeptname' + i],
                  empcode: e['sjempcode' + i],
                  picurl:
                    this.userInfo.cappicurl + 'Cap_' + e['sjempid' + i] + '.jpg'
                  // picurl: '/img/interview_pic1.e86dad2c.png'
                })
              if (e['tjempid' + i])
                levelArray2.push({
                  empid: e['tjempid' + i],
                  empname: e['tjempname' + i],
                  deptname: e['tjdeptname' + i],
                  empcode: e['tjempcode' + i],
                  picurl:
                    this.userInfo.cappicurl + 'Cap_' + e['tjempid' + i] + '.jpg'
                })
              if (e['xjempid' + i])
                levelArray3.push({
                  empid: e['xjempid' + i],
                  empname: e['xjempname' + i],
                  deptname: e['xjdeptname' + i],
                  empcode: e['xjempcode' + i],
                  picurl:
                    this.userInfo.cappicurl + 'Cap_' + e['xjempid' + i] + '.jpg'
                })
            }

            children.push({
              pjper: e.sjper,
              levelName: this.$t('jxAppraiserReview.revTxt6'),
              levelArray: levelArray1
            })
            children.push({
              pjper: e.tjper,
              levelName: this.$t('jxAppraiserReview.revTxt7'),
              levelArray: levelArray2
            })
            children.push({
              pjper: e.xjper,
              levelName: this.$t('jxAppraiserReview.revTxt8'),
              levelArray: levelArray3
            })
            khplanid = e.khplanid
            arr.push({
              autoid: e.autoid,
              isagreekpjr: e.isagreekpjr,
              khplanid: e.khplanid,
              khplanname: e.khplanname,
              children: children
            })
          }
          // console.log(khplanid)
          // e.picurl = this.userInfo.cappicurl + 'Cap_' + e.empid + '.jpg'
          // arr[arr.length - 1].children.push(e)
        })
        this.appraisersList = arr
        console.log(this.appraisersList, 'this.appraisersList')

        // 获取图片
        this.appraisersList.forEach(e => {
          e.children.forEach(item => {
            item.levelArray.forEach(it => {
              // 判断图片是否存在，不存在则替换为默认图片
              checkPic(it, 'picurl')
            })
          })
        })
        // 加载状态结束
        this.loadingAwait = false
        this.finishedAwait = true
        Toast(this.$t('module.refreshSuccessfully'))
        // this.isLoadingAwait = false;
      })
    },
    // 下拉刷新
    onRefreshAwait() {
      this.finishedAwait = false
      this.loadingAwait = true
      this.onLoadAwait()
    },
    // 同意弹窗回调函数
    beforeClose(action, done) {
      if (action === 'confirm') {
        agreeUser360Pjr({
          autoid: this.programAutoid,
          username: this.userInfo.username
        }).then(res => {
          if (res.iserror == '0') {
            Toast({
              message: this.$t('jxAppraiserReview.revTxt9'),
              position: 'top'
            })
            done()
          }
        })
      } else {
        done()
      }
    },
    // 同意按钮
    agreeEntry(autoid) {
      this.programAutoid = autoid
      Dialog.confirm({
        title: this.$t('jxAppraiserReview.revTxt3'),
        message: this.$t('jxAppraiserReview.revTxt10'),
        beforeClose: this.beforeClose
      })
    },
    // 不同意按钮
    disagreeEntry(autoid) {
      this.showDisagree = true
      this.programAutoid = autoid
    },
    // 不同意弹窗确认按钮
    confirm() {
      if (this.disagreeReason) {
        notAgreeUser360Pjr({
          autoid: this.programAutoid,
          username: this.userInfo.username,
          notagreereason: this.disagreeReason
        }).then(res => {
          if (res.iserror == '0') {
            Toast({
              message: this.$t('jxAppraiserReview.revTxt11'),
              position: 'top'
            })
            done()
          }
        })
      } else {
        Toast({
          message: this.$t('jxAppraiserReview.revTxt12'),
          position: 'top'
        })
      }
    },
    // 关闭不同意弹窗
    closeDia() {
      // 这是每次关闭弹窗后清空参数
      // this.programAutoid = 0
      this.disagreeReason = ''
    }
  }
}
</script>

<style lang="less" scoped>
.jxAppraiserReview {
  height: 100vh;
  overflow: hidden;
  background: #eceaea;
  .van-nav-bar {
    background: #2b8df0;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    ::v-deep .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
    }
  }
  .overflow_div {
    overflow: auto;
    height: calc(100% - 92px);
    // background: #fff;
    /deep/ .program_collapse {
      .program_collapse_item:first-child {
        margin-top: 20px;
      }
      .program_collapse_item {
        margin-bottom: 20px;
        .van-collapse-item__title {
          background: #2b8df0;
          .van-cell__title {
            span {
              height: 44px;
              font-size: 36px;
              font-family: DIN;
              font-weight: bold;
              line-height: 44px;
              color: #ffffff;
            }
          }
          .van-icon {
            color: #ffffff;
          }
        }
        .van-collapse-item__wrapper {
          .van-collapse-item__content {
            background-color: #eceaea;
          }
        }
      }
    }
    /deep/ .children_collapse {
      .van-collapse-item {
        padding-bottom: 20px;
        .van-collapse-item__title {
          background: #ffffff;
          .van-cell__title {
            span {
              height: 44px;
              font-size: 32px;
              font-family: DIN;
              font-weight: bold;
              line-height: 44px;
              color: #000000;
            }
          }
          .van-icon {
            color: #969799;
          }
        }
        .van-collapse-item__wrapper {
          .van-collapse-item__content {
            padding: 0;
            img {
              width: 106px;
              height: 106px;
              margin-right: 30px;
            }
            .van-cell__title {
              span {
                height: 36px;
                font-size: 32px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                line-height: 62px;
                color: #333333;
              }
              .van-cell__label {
                height: 24px;
                font-size: 24px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                line-height: 40px;
                color: #666666;
              }
            }
            .van-cell__value {
              span {
                font-size: 32px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                line-height: 62px;
                color: #2b8df0;
              }
            }
          }
        }
      }
    }
  }
  .test_button {
    display: flex;
    width: 100%;
    height: 120px;
    padding: 20px;
    box-sizing: border-box;
    background: #fff;
    .wai_div {
      flex: 1;
      text-align: center;
      .van-button {
        height: 100%;
        width: 60%;
      }
    }
    .wai_div:first-child {
      border-right: 1px solid #ccc;
    }
  }
  .entry_button {
    display: flex;
    align-items: center;
    // position: absolute;
    // bottom: 0;
    width: 100%;
    height: 120px;
    padding: 20px;
    box-sizing: border-box;
    background: #fff;
    .disagree {
      color: #f53838;
    }
    span {
      display: inline-block;
      width: 50%;
      height: 100%;
      line-height: 80px;
      box-sizing: border-box;
      font-size: 36px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #2b8df0;
      text-align: center;
    }
    span:first-child {
      border-right: 1px solid #ccc;
    }
  }
  .van-dialog {
    .van-dialog__header {
      font-size: 36px;
      font-family: Source Han Sans SC;
      font-weight: 500;
      line-height: 62px;
      color: #333333;
    }
    /deep/.van-dialog__content {
      .disagreeReason {
        .van-field__value {
          border: 2px solid #cccccc;
          opacity: 1;
          border-radius: 8px;
        }
      }
    }
  }
}
</style>
